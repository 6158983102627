import {
    Routes,
    Route,
    Navigate
  } from "react-router-dom";
import React, { Suspense, lazy } from 'react';
import PageLayout from "./components/pageLayout";
import Loader from "./components/loader";

const Home = React.lazy(() => import('./components/home/home'));
const AboutUs = React.lazy(() => import('./components/aboutUs/aboutUs'));
const NoMatchFound = React.lazy(() => import("./components/noMatchFound/noMatchFound"));
const CoCurricularActivities = React.lazy(() => import("./components/coActivities/coActivities"));
const Academics = lazy(() => import("./components/academics/academics"));
const Celebrations = lazy(() => import("./components/celebrations/celebrations"));
const ChildrensDay = lazy(() => import("./components/childrensDay/childrensDay"));
const ContactPage = lazy(() => import("./components/contactPage/contactPage"));
const MandatoryPublicDisclosure = lazy(()=> import('./components/mandatoryPublicDisclosure/mandatoryPublicDisclosure'))

const CreateRoutes = ()=>{
  return(
     <Suspense fallback={<Loader/>}>
     <Routes>
  
        <Route path="/" element={<PageLayout />}>
          <Route index path='/' element={<Home />} />
          <Route index path='/academics' element={<Academics />} />
          <Route index path='/activities' element={<CoCurricularActivities/>} />
          <Route index path='/celebrations' element={<Celebrations />} />
          <Route index path='/childrensday' element={<ChildrensDay />} />
          <Route index path='/publicDisclosure' element={<MandatoryPublicDisclosure />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/404" element={<NoMatchFound />} />
          <Route path="*" element={<Navigate replace to="/404"/>} />
        </Route>

      </Routes>
      </Suspense>
  
  );

  }

  export default CreateRoutes;