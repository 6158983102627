function PictureHolder({ imageLarge = '', ImageSmall = '', ImageUnavailable = '', altText = '',loading ='lazy' }) {
    return (
        <div className='app-image-container' itemScope itemType="https://schema.org/Product">
            <picture>
                <source media="(min-width:650px)"
                    srcSet={imageLarge}
                />
                <source media="(min-width:465px)"
                    srcSet={ImageSmall}
                />
                <img className='app-home-image' src={ImageUnavailable}
                    loading= {loading}
                    srcSet={ImageSmall} alt={altText}
                    itemProp="image" />
            </picture>
        </div>
    )
}

export default PictureHolder