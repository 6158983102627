import './App.css';
import { BrowserRouter } from "react-router-dom";
import CreateRoutes from './createRoutes'

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <CreateRoutes />
    </div>
    </BrowserRouter>
  );
}

export default App;