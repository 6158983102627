import './footer.scss';

function Footer() {
    const current_year = new Date().getFullYear();

    const menu_links = {
      HOME:"Home",
      ACADEMICS:"Academics",
      CO_CURRICULUM_ACTIVITIES:"Co-Curriculum",
      CELEBRATIONS:"Celebrations",
      ABOUT_US:"About us",
      CONTACT_US:"Contact us",
      MANDATORY_PUBLIC_DISCLOSURE:'Mandatory Public Disclosure'
  };

    return (
        <footer className="app-footer">
          <div className='container'>
            <div className='row mainFooter'>
                <div className='col-xl-4 col-md-6 align-items-stretch d-flex section1'>
                  <div>
                    <h4>{"Reach us"}</h4>
                    <h6>{"ELF'S PUBLIC SCHOOL"}</h6>
                    <p>{"Address:"}<br/>{"Therkku Kattu Kottai,Vengampatti,Mallur,Tamil Nadu - 636203"}</p>
                    <p>{"Mobile:"}<br/>{"9789296860"}</p>
                    <p>{"Email:"}<br/>{"elfscbseschool@gmail.com"}</p>
                  </div>
                </div>
                <div className='col-xl-4 col-md-6 align-items-stretch d-flex section2'>
                  <div>
                    <h4>{"Important Links"}</h4>
                    <ul>
                      <li><a href={"/"}>{menu_links.HOME}</a></li>
                      <li><a href={"/aboutus"}>{menu_links.ABOUT_US}</a></li>
                      <li><a href={"/academics"}>{menu_links.ACADEMICS}</a></li>
                      <li><a href={"/activities"}>{menu_links.CO_CURRICULUM_ACTIVITIES}</a></li>
                      <li><a href={"/celebrations"}>{menu_links.CELEBRATIONS}</a></li>
                      <li><a href={"/publicdisclosure"}>{menu_links.MANDATORY_PUBLIC_DISCLOSURE}</a></li>
                      <li><a href={"/contactus"}>{menu_links.CONTACT_US}</a></li>
                    </ul>
                  </div>
                </div>
                <div className='col-xl-4 col-md-6 align-items-stretch d-flex section3'>
                  <div>
                    <h4>{"Find us"}</h4>
                    <div className='inner'>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3909.1378244226057!2d78.13123821432853!3d11.541969491804837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babe93c7a9e6cf7%3A0xefe45a9b1acea597!2sElfs%20Public%20School%20Vengampatty%20Mallur!5e0!3m2!1sen!2sin!4v1646217211827!5m2!1sen!2sin" 
                      style={{border:0}}
                      title={'Elfs School map'}
                      height={"250px"}
                      width={"100%"}
                      allowFullScreen="" loading="lazy">
                      </iframe>
                    </div>
                  </div>                
                </div>
            </div>            
          </div>  
          <div className='copyRight'><p>Copyright &copy; The Elf's School {current_year} </p></div>
        </footer>
     )
    
}
  
export default Footer;